
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/authentication/AuthGuard";
import React from "react";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

/** LAZY LOADING COMPONENETS */
const Login = Loadable(lazy(() => import("./pages/registration/Login")));
const Register = Loadable(lazy(() => import("./pages/registration/Register")));
const ForgetPassword = Loadable(lazy(() => import("./pages/registration/ForgetPassword")));
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));
const OnboardCompany = Loadable(lazy(() => import("./pages/onboarding/org/main")));
const EmployeeOnboarding = Loadable(lazy(() => import("./pages/onboarding/empl/main")));
const Rota = Loadable(lazy(() => import("./pages/dashboards/shift-management/main")));
const Staff = Loadable(lazy(() => import("./pages/dashboards/staff")));
const StaffDocuments = Loadable(lazy(() => import("./pages/dashboards/staff-documents")));

// error
const Error = Loadable(lazy(() => import("./pages/404")));
const CS = Loadable(lazy(() => import("./pages/coming-soon")));
const Pricing = Loadable(lazy(() => import("./pages/pricing")));

// routes
const routes = [
  {
    path: "/dashboard",
    element: <Navigate to="/" />,
  },
  {
    path: "login",
    element: (
        <Login />
    ),
  },
  {
    path: "register",
    element: (
        <Register />
    ),
  },
  {
    path: "forget-password",
    element: (
        <ForgetPassword />
    ),
  },
  {
    path: "org/onboarding",
    element: (
        <OnboardCompany />
    ),
  },
  {
    path: "empl/onboarding",
    element: (
        <EmployeeOnboarding />
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "rota",
        element: <Rota />,
      },
      {
        path: "employees",
        element: <Staff />,
      },
      {
        path: "documents",
        element: <StaffDocuments />,
      },
      {
        path: "payslips",
        element: <CS />,
      },
      {
        path: "discounts",
        element: <CS />,
      },
      {
        path: "training",
        element: <CS />,
      },
      {
        path: "export",
        element: <CS />,
      },
      {
        path: "org/settings",
        element: <OnboardCompany />,
      },
      {
        path: "settings",
        element: <CS />,
      },
      {
        path: "org/pricing",
        element: (
            <Pricing />
        ),
      },
      { 
        path: "*", element: <Error /> 
      } 
    ],
  },
  { path: "*", element: <Error /> } 
];

export default routes;
