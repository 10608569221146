import {
  AppBar,
  Box,
  Grid,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, } from "react";
import ProfilePopover from "./popovers/ProfilePopover";
import { useGlobalState } from "../../data-context";
import NotificationsPopover from "./popovers/NotificationsPopover";

// root component interface
interface DashboardNavBarProps {
  setShowMobileSideBar: () => void;
}

// custom styled components
const DashboardNavbarRoot = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(8px)",
  backgroundColor: "#f3f7fd39",
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    // paddingLeft: 100,
    // paddingRight: 100,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(({ theme }) => ({
  width: 25,
  height: 3,
  margin: "5px",
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

// root component
const DashboardNavbar: FC<DashboardNavBarProps> = ({
  setShowMobileSideBar,
}) => {
  const { state, } = useGlobalState();
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));



  if (downSm) {
    return (
      <DashboardNavbarRoot position="sticky" sx={{minWidth: '100vw',}}>
        <StyledToolBar >
          <Box sx={{ cursor: "pointer" }} onClick={setShowMobileSideBar}>
            <ToggleIcon />
            <ToggleIcon />
            <ToggleIcon />
          </Box>

          <Box flexGrow={1} textAlign="center">
            <img
              src="/logo.png"
              height="50"
              alt="Logo"
            />
          </Box>

          <ProfilePopover />
        </StyledToolBar>
      </DashboardNavbarRoot>
    );
  }

  return (
    <DashboardNavbarRoot sx={{paddingLeft: '100px', paddingRight: '100px'}} position="sticky">
      <StyledToolBar >
        <Grid display={'flex'}>
          <Grid xs={10} item>
            <Typography  fontSize={18} fontWeight="800"  color="text.primary"   >
              {state.business?.name || 'Your Company Name'}
          </Typography>
          </Grid>
        
        </Grid>
        <Box flexGrow={1} ml={1} />

        {upSm && (
          <>
            <NotificationsPopover />
            {/* <ServicePopover /> */}
          </>
        )}
        <ProfilePopover />
      </StyledToolBar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
