import React, { createContext, useContext, useEffect, useState } from "react";
import { LoadingView } from "./components/Layouts/loading";
import { fAuth, db, retrieveState, uploadState } from "./firebase/init";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const DataContext = createContext(null);


export const DataProvider = ({ children }) => {
  const [user, loading] = useAuthState(fAuth);
  const [state, setState] = useState({
    business: {},
    user: {},
    isLoading: true
  });

  useEffect(() => {
    setState({ ...state, isLoading: loading });
  }, [loading]);



  const retrieveStateFromFirestore = async () => {
    if (user) {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setState({...state, user: userData, isLoading: true });
          if (userData.business) {
            const businessDocRef = doc(db, "organizations", userData.business);
            const businessDocSnap = await getDoc(businessDocRef);
            
            if (businessDocSnap.exists()) {
              console.log(userData, businessDocSnap);
              setState({...state, business: businessDocSnap.data(), user: userData, isLoading: false });
            }
          }
        }
      } catch (error) {
        retrieveStateFromDatabase();
        console.error("Error retrieving state from Firestore:", error);
      }
    }
  };



  const retrieveStateFromDatabase = async () => {
    if (user) {

        const s = await retrieveState(user.uid);
        if (s) {
          setState(s);
          console.log("State retrieved from Firebase Realtime Database");
        

        } else {
          console.log("No state found for", user.uid, "trying to retrieve from Firestore");
          retrieveStateFromFirestore();
        }
      
    }
  };

  const uploadStateToDatabase = async (stateData) => {
    if (user) {
      try {
        await uploadState(user.uid, stateData);
      } catch (error) {
        console.error("Error uploading state to Firebase Realtime Database:", error);
      }
    }
  };




  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [state, user]);
  
  const handleBeforeUnload = () => {
    // Perform any cleanup actions before the user leaves the website
    if (state) {
      uploadStateToDatabase({...state,});
    }
  };
  
  useEffect(() => {
    retrieveStateFromFirestore();
  }, [user]);

  return (
    <DataContext.Provider value={{ state, setState }}>
      {children}
      {!state.user && !state.business && <LoadingView />}
    </DataContext.Provider>
  );
};
export const useGlobalState = () => useContext(DataContext);
