import { AccessTimeOutlined, Dashboard, DiscountOutlined, FileDownloadOutlined, FolderSharedOutlined, PeopleAltOutlined, RequestQuoteOutlined, SchoolOutlined, } from "@mui/icons-material";

const index = [
  {
    title: "Dashboard",
    Icon: Dashboard,
    path: "/",
  },
  {
    title: "Rota",
    Icon: AccessTimeOutlined,
    path: "/rota",
  },
  {
    title: "Employees",
    Icon: PeopleAltOutlined,
    path: "/employees",
  },
  {
    title: "Documents",
    Icon: FolderSharedOutlined,
    path: "/documents",
  },
  {
    title: "Payslips",
    Icon: RequestQuoteOutlined,
    path: "/payslips",
  },
  {
    title: "Staff Discount",
    Icon: DiscountOutlined,
    path: "/discounts",
  },
  {
    title: "Training",
    Icon: SchoolOutlined,
    path: "/training",
  },
  {
    title: "Export",  
    Icon: FileDownloadOutlined,
    path: "/export",
  },
];

export default index;
