import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { useGlobalState } from "./data-context";
import HiveTheme from "./theme";
import { messaging } from "./firebase/init";
import { getToken, onMessage } from "firebase/messaging";

const App: FC = () => {
  const allPages = useRoutes(routes);

  const {state, setState } = useGlobalState();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

     // Request permission to receive notifications
     const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        const FCMToken = await getToken(messaging, { vapidKey: 'BMnK3asdUFc6Q55MGG73vafJpz0y2i_e5En18aY5PeojLNMDonfahwcmy_z3LbgZopIsFkdZWYe-ugYFxXNMEYU' });
        setState({ ...state, user: { ...state.user, FCMToken }});
      } catch (err) {
        console.error("Permission denied or error occurred", err);
      }
    };

    // Request permission to receive notifications
    requestPermission();


    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Display notification using a library like react-hot-toast
      setState({ ...state, notification: [...state.notification, payload.notification] });
      toast(payload.notification?.title || 'Notification', {
      });
    });
  }, []);





  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={HiveTheme}>
            <CssBaseline />
            <Toaster toastOptions={toasterOptions} />
            {allPages}
        </ThemeProvider>
      </StyledEngineProvider>
  );
};

export default App;
